<template>
  <div class="account-setting pane-wrapper">
    <a-tabs default-active-key="1" tabPosition="left">
      <a-tab-pane key="1" tab="基本设置">
        <h1 class="t1">基本设置</h1>
        <br />
        <a-form-model :model="entity" @submit.prevent="handleSave">
          <!-- <a-form-model-item label="邮箱" prop="email">
            <a-input v-model="entity.email" allowClear></a-input>
          </a-form-model-item> -->
          <a-form-model-item label="名称" prop="user.name">
            <a-input v-model="entity.name" allowClear></a-input>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 8, offset: 4 }">
            <a-space>
              <a-button htmlType="submit" :loading="loading" type="primary">
                保存
              </a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane key="2" tab="安全设置">
        <h1 class="t1">安全设置</h1>
        <br />
        <a-form-model
          :model="userPassword"
          :layout="'horizontal'"
          :rules="userPasswordRules"
          @submit="handleUpdatePassword"
        >
          <a-form-model-item label="原始密码" prop="oldPassword">
            <a-input-password
              v-model="userPassword.oldPassword"
              size="large"
              placeholder="请输入原始密码"
            ></a-input-password>
          </a-form-model-item>
          <a-form-model-item label="新密码" prop="password">
            <a-input-password
              v-model="userPassword.password"
              size="large"
              placeholder="请输入修改密码"
            ></a-input-password>
          </a-form-model-item>
          <a-form-model-item label="确认密码" prop="confirmedPassword">
            <a-input-password
              v-model="userPassword.confirmedPassword"
              size="large"
              placeholder="确认密码"
            ></a-input-password>
          </a-form-model-item>

          <a-form-model-item :wrapper-col="{ span: 20, offset: 8 }">
            <a-space>
              <a-button type="primary" htmlType="submit"> 修改密码 </a-button>
              <a-button @click="handlePwdClose"> 取消 </a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AccountService from "@/services/account";
const defaultEntity = {};
export default {
  data() {
    return {
      service: AccountService,
      visible: false,
      visibleSortModal: false,
      loading: false,
      entity: JSON.parse(JSON.stringify(defaultEntity)),
      target: {},
      userPassword: {},
      userPasswordRules: {
        oldPassword: [
          {
            trigger: ["change", "blur"],
            required: true,
            message: "至少6位密码，区分大小写",
          },
        ],
        password: [
          {
            trigger: ["change", "blur"],
            required: true,
            message: "至少6位密码，区分大小写",
          },
        ],
        confirmedPassword: [
          {
            trigger: ["change", "blur"],
            required: true,
            message: "至少6位密码，区分大小写",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  methods: {
    ...mapActions({
      getAccount: "getAccount",
    }),
    handlePwdClose() {
      this.userPassword = {};
    },
    async handleSave() {
      try {
        const entity = {
          id: this.entity.id,
          name: this.entity.name,
        };

        await this.service.updateEntity(entity);
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        await this.getAccount();
        this.entity = JSON.parse(JSON.stringify(this.account));
      }
    },
    async handleUpdatePassword() {
      try {
        if (
          !this.userPassword.password ||
          !this.userPassword.oldPassword ||
          !this.userPassword.confirmedPassword
        ) {
          this.$message.error("请输入原密码、新密码和确认密码！");
        } else if (
          this.userPassword.password != this.userPassword.confirmedPassword
        ) {
          this.$message.error("两次密码输入不一致，请重新输入");
        } else {
          await this.service.updatePassword(this.userPassword, this.entity);
        }
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.userPassword = {};
      }
    },
  },
  mounted() {
    console.log(this.account);

    this.entity = JSON.parse(JSON.stringify(this.account));
  },
};
</script>

<style>
.account-setting {
  padding: 24px;
  background-color: #ffffff;
}
.t1 {
  font-size: 20px;
}
</style>